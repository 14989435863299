import React from 'react';
import {Link} from 'gatsby';
import tw, {styled, css} from 'twin.macro';

import Transactions from "@/components/illustrations/transactions";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

const Wrapper = styled.div`
    ${tw`bg-gradient-to-r
    from-secondary-background
    to-secondary-background
    rounded-lg
    shadow-xl
    overflow-hidden
    lg:grid
    lg:grid-cols-2
    lg:gap-0`}
`;

const Image = styled.div`
   ${tw`
	    absolute
	    inset-0
	    w-full
	    h-full
	    transform
	    translate-x-6
	    translate-y-6
	    rounded-md
	    object-cover
	    object-left-top
	    sm:translate-x-16
	    lg:translate-y-10
    `}
`;

const Btn = styled(Link)(
	() => [
		tw`
		mt-8
		bg-primary-background
		border
		border-transparent
		rounded-md
		shadow
		py-4
		px-8
		inline-flex
		items-center
		text-2xl
		leading-6
		font-extrabold
		text-primary-text
		focus:outline-none
		focus:border-primary-background
		transition
		duration-150
		ease-in-out`
	]
);

const StudyCta: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-6 lg:py-6 lg:pr-0 xl:py-10 xl:px-10">
				<div className="lg:self-center">
					<b className="text-3xl leading-9 font-extrabold text-secondary-text sm:text-4xl sm:leading-10">
						<span className="block">
							Light and fast
						</span>
						<span className="block text-white text-4xl sm:text-4xl lg:text-5xl sm:leading-none">
							Create your own Wireguard server
						</span>
					</b>
					<p className="mt-4 text-lg leading-7 text-secondary-text mt-8">
						Wireguard is a light, secure and fast protocol that will
						make you forget you're even using a VPN at all.
					</p>
					<Btn to={'/create/wireguard'}>
						Build your own
					</Btn>
				</div>
			</div>
			<div className="relative pb-3/5 -mt-6 md:pb-1/2 hidden lg:block">
				<Image>
					<Transactions
						strokeColor={'var(--primary-700)'}
						primaryColor={'var(--primary-200)'}
						bgColor={'var(--primary-100)'}
					/>
				</Image>
			</div>
		</Wrapper>
	);
};

export default StudyCta;
