import React, {SVGProps} from 'react';

const Transactions = (props: SVGProps<SVGSVGElement> & {
	primaryColor: string;
	bgColor: string;
	strokeColor: string;
}) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width="600"
		height="600" viewBox="0 0 1027.99206 529.55555">
		<rect x="305.99206" y="295.38557" width="343.71997" height="234.16998" fill={props.primaryColor}/>
		<path
			d="M754.88605,453.21778h-338.24a4.11628,4.11628,0,0,0-4.11005,4.11v228.69a4.10986,4.10986,0,0,0,4.11005,4.11h338.24a4.10984,4.10984,0,0,0,4.11-4.11v-228.69A4.11626,4.11626,0,0,0,754.88605,453.21778Zm1.37,232.8a1.36568,1.36568,0,0,1-1.37,1.37h-338.24a1.36569,1.36569,0,0,1-1.37-1.37v-228.69a1.37207,1.37207,0,0,1,1.37-1.37h338.24a1.37206,1.37206,0,0,1,1.37,1.37Z"
			transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
		<rect x="344.33535" y="429.58731" width="154.74254" height="17.80224" rx="2" fill={props.strokeColor}/>
		<path
			d="M726.126,599.74775h-49.29a4.10991,4.10991,0,0,0-4.11005,4.11v38.34a4.10986,4.10986,0,0,0,4.11005,4.11h49.29a4.1098,4.1098,0,0,0,4.11-4.11v-38.34A4.10985,4.10985,0,0,0,726.126,599.74775Zm1.37,42.45a1.37206,1.37206,0,0,1-1.37,1.37h-49.29a1.37212,1.37212,0,0,1-1.37006-1.37v-38.34a1.36574,1.36574,0,0,1,1.37006-1.37h49.29a1.36568,1.36568,0,0,1,1.37,1.37Z"
			transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
		<rect x="598.67562" y="423.37233" width="33.60304" height="6.95235" rx="2" fill={props.strokeColor}/>
		<rect x="598.67562" y="434.32756" width="33.60304" height="6.95235" rx="2" fill={props.strokeColor}/>
		<rect x="598.67562" y="445.28278" width="33.60304" height="6.95235" rx="2" fill={props.strokeColor}/>
		<rect x="348.44356" y="284.4306" width="71.20896" height="30.12687" rx="2" fill={props.strokeColor}/>
		<path
			d="M475.52961,484.71625c0,5.29411-2.45241,9.58583-5.47761,9.58583s-5.47761-4.29172-5.47761-9.58583,5.47761-9.58582,5.47761-9.58582S475.52961,479.42215,475.52961,484.71625Z"
			transform="translate(-86.00397 -185.22222)" fill={props.primaryColor}/>
		<polygon
			points="901.992 161.837 899.992 161.837 899.992 385.451 672.992 385.451 672.992 387.451 901.992 387.451 901.992 161.837"
			fill={props.strokeColor}/>
		<polygon
			points="305.992 387.451 76.992 387.451 76.992 161.837 78.992 161.837 78.992 385.451 305.992 385.451 305.992 387.451"
			fill={props.strokeColor}/>
		<path
			d="M1113.35519,223.03181h-1.28168v-2.24294h.96126a.64087.64087,0,0,0,.64084-.64084V208.6129a.64085.64085,0,0,0-.64084-.64084h-.96126v-6.551a13.95586,13.95586,0,0,0-13.956-13.95591h-1.42421v-1.60211a.64085.64085,0,0,0-.64084-.64084h-11.53513a.64085.64085,0,0,0-.64084.64084v1.60211H875.46088a13.95586,13.95586,0,0,0-13.956,13.95591v296.9942a13.9559,13.9559,0,0,0,13.956,13.956h222.65667a13.9559,13.9559,0,0,0,13.956-13.956V235.84861h1.28168a.64087.64087,0,0,0,.64084-.64084V223.67265A.64085.64085,0,0,0,1113.35519,223.03181Z"
			transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
		<rect x="787.03482" y="11.37165" width="229.42338" height="304.40573" rx="1.9048" fill={props.bgColor}/>
		<rect x="875.401" y="160.55814" width="39.35653" height="11.66295" rx="2" fill={props.strokeColor}/>
		<rect x="827.82072" y="69.75378" width="134.51708" height="4.99841" rx="2" fill={props.strokeColor}/>
		<rect x="827.82072" y="85.58206" width="134.51708" height="4.99841" rx="2" fill={props.strokeColor}/>
		<rect x="827.82072" y="101.41034" width="134.51708" height="4.99841" rx="2" fill={props.strokeColor}/>
		<rect x="827.82072" y="117.23863" width="134.51708" height="4.99841" rx="2" fill={props.strokeColor}/>
		<rect x="827.82072" y="133.06691" width="134.51708" height="4.99841" rx="2" fill={props.strokeColor}/>
		<circle cx="890.31727" cy="261.97835" r="41.77968" fill={props.primaryColor}/>
		<path
			d="M988.63566,479.42627a52.38194,52.38194,0,1,1,52.38193-52.38194A52.44121,52.44121,0,0,1,988.63566,479.42627Zm0-102.85908a50.47714,50.47714,0,1,0,50.47714,50.47714A50.53392,50.53392,0,0,0,988.63566,376.56719Z"
			transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
		<path
			d="M241.496,273.13387h-1.71341V226.19551a27.16665,27.16665,0,0,0-27.16673-27.16667H113.1707A27.16664,27.16664,0,0,0,86.004,226.19551V483.703a27.16664,27.16664,0,0,0,27.16673,27.16667h99.44519A27.16665,27.16665,0,0,0,239.78262,483.703V306.54539H241.496Z"
			transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
		<path
			d="M233.99737,227.79218V484.92413a20.28849,20.28849,0,0,1-20.28483,20.29192H113.78728a20.28842,20.28842,0,0,1-20.28475-20.29192V227.79218a20.28688,20.28688,0,0,1,20.28475-20.28471h12.12326a9.63757,9.63757,0,0,0,8.925,13.27583h56.97157a9.63747,9.63747,0,0,0,8.925-13.27583h12.98047A20.287,20.287,0,0,1,233.99737,227.79218Z"
			transform="translate(-86.00397 -185.22222)" fill={props.bgColor}/>
		<rect x="64.36182" y="173.77302" width="26.76842" height="7.93258" rx="2" fill={props.strokeColor}/>
		<rect x="32" y="112.01225" width="91.49206" height="3.39968" rx="1.69984" fill={props.strokeColor}/>
		<rect x="32" y="122.77789" width="91.49206" height="3.39968" rx="1.69984" fill={props.strokeColor}/>
		<rect x="32" y="133.54352" width="91.49206" height="3.39968" rx="1.69984" fill={props.strokeColor}/>
		<rect x="32" y="144.30916" width="91.49206" height="3.39968" rx="1.69984" fill={props.strokeColor}/>
		<rect x="32" y="155.0748" width="91.49206" height="3.39968" rx="1.69984" fill={props.strokeColor}/>
		<circle cx="69.87495" cy="256.35481" r="28.81288" fill={props.primaryColor}/>
		<path
			d="M164.37142,463.80112A36.12462,36.12462,0,1,1,200.496,427.67651,36.16549,36.16549,0,0,1,164.37142,463.80112Zm0-70.9356a34.811,34.811,0,1,0,34.811,34.811A34.85016,34.85016,0,0,0,164.37142,392.86552Z"
			transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
		<rect x="147.50456" y="375.18969" width="28" height="28" fill={props.bgColor}/>
		<path d="M278.50853,572.41192h-34v-34h34Zm-32.52174-1.47826h31.04348V539.89018H245.98679Z"
			 transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
		<rect x="774.50456" y="375.18969" width="28" height="28" fill={props.bgColor}/>
		<path d="M905.50853,572.41192h-34v-34h34Zm-32.52174-1.47826h31.04348V539.89018H872.98679Z"
			 transform="translate(-86.00397 -185.22222)" fill={props.strokeColor}/>
	</svg>

);

export default Transactions;
